import {
  bgColorMapping,
  colorMapping,
} from '@/components/teacher/Dashboard/LatestAssignments/helpers';

export const backgroundColorsByExam = {
  ISEB: '#F9FAFA',
  LON11PLUS: '',
  '11PLUS': '',
  CAT4: '',
  CEMSEL: '',
  GL11PLUS: '',
  SAT: '',
};

export const variantsBtnByExamCode = {
  ISEB: 'purple',
  LON11PLUS: '',
  '11PLUS': '',
  CAT4: '',
  CEMSEL: '',
  GL11PLUS: '',
  SAT: '',
};

export const avatarBorderByExamCode = {
  ISEB: 'rgb(151,71,255)',
  LON11PLUS: '',
  '11PLUS': '',
  CAT4: '',
  CEMSEL: '',
  GL11PLUS: '',
  SAT: '',
};
export const colorByExamCode = {
  ISEB: 'rgb(151,71,255)',
  LON11PLUS: '',
  '11PLUS': '',
  CAT4: '',
  CEMSEL: '',
  GL11PLUS: '',
  SAT: '',
};

export const getExamTypeColor = (type: string) => {
  switch (type) {
    case 'EXAM':
      return colorMapping.EXAM;
    case 'CLASSWORK':
      return colorMapping.CLASSWORK;
    case 'HOMEWORK':
      return colorMapping.HOMEWORK;
    case 'QUIZ':
      return colorMapping.QUIZ;
    case 'READING':
      return colorMapping.READING;
    default:
      return colorMapping.CLASSWORK;
  }
};

export const getExamTypeBgColor = (type: string) => {
  switch (type) {
    case 'EXAM':
      return bgColorMapping.EXAM;
    case 'CLASSWORK':
      return bgColorMapping.CLASSWORK;
    case 'HOMEWORK':
      return bgColorMapping.HOMEWORK;
    case 'QUIZ':
      return bgColorMapping.QUIZ;
    case 'READING':
      return bgColorMapping.READING;
    default:
      return bgColorMapping.CLASSWORK;
  }
};
